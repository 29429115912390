import React, { useState, useEffect } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import styled, { css } from 'styled-components';

import Button from 'components/elements/Button';


const TeamListItem = ({
  person,
}) => {

  const [bioOpen, setBioOpen] = useState(false);

  const handleViewBio = () => {
    setBioOpen(true)
    document.getElementById('___gatsby').classList.add('fixed');
  }

  const handleCloseBio = () => {
    setBioOpen(false);
    document.getElementById('___gatsby').classList.remove('fixed');
  }

  return (
    <Container>
      <Columns focalPoint={person.photo[0].focalPoint}>
        <Headshot src={person.photo[0].url} />
      </Columns>
      <Columns>
        <FullName>{person.fullName}</FullName>
        <Role>{person.role}</Role>
        <StyledButton
          onClick={handleViewBio}
        >
          View Bio
        </StyledButton>
        <BioContainer className={bioOpen ? 'open' : ''}>
          <CloseButton 
            onClick={handleCloseBio}
          />
          <FullName>{person.fullName}</FullName>
          <div dangerouslySetInnerHTML={ {__html: person.bio} } />
        </BioContainer>
      </Columns>
    </Container>
  )
};

TeamListItem.propTypes = {

};

export default TeamListItem;

const Container = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
  @media all and (min-width: 700px) {
    flex-direction: row;
    margin: 4em 0;
  }
`;

const FullName = styled.h4`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 3em;
  margin-top: 1em;
`;

const Role = styled.h5`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 3em;
  margin-top: 1em;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${( { focalPoint } ) =>
  focalPoint &&
    css`
      img {
        object-position: ${focalPoint[0] * 100 + '%'} ${focalPoint[1] * 100 + '%'};
      }
    `
  };
  @media all and (min-width: 700px) {
    width: 50%;
    li:nth-of-type(even) & {
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
      }
    }
  }
`;

const Headshot = styled(LazyLoadImage)`
  width: 90%;
  height: auto;
  max-width: 250px;
  border-radius: 50%;
  object-fit: cover;
  max-height: 250px;
  @media all and (min-width: 700px) {
    max-width: 400px;
    max-height: 400px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  background-color: transparent;
  cursor: pointer;
  &:after,
  &:before {
    content:"";
    width: 2px;
    height: 25px;
    background-color: ${({ theme }) => theme.colors.Primary};
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.Primary};
  color: white;
  margin: 1.5em 0;
  font-size: 1.6em;
`;

const BioContainer = styled.div`
  padding: 0 2em;
  margin: 2em 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;
  padding: 2em 4em;
  width: 100%;
  height: 70vh;
  background-color: white;
  max-width: 800px;
  outline: 9999px solid rgba(0,0,0,0.2);
  > div {
    overflow-y: auto;
    height: 90%;
    padding-bottom: 6em;
    padding-top: 3em;
  }
  display: none;
  p {
    color: ${({ theme }) => theme.colors.Primary};
    line-height: 1.4;
    font-size: 2em;
    margin: 0.5em 0;
    em {
      font-style: italic;
    }
    strong {
      font-weight: 700;
    }
  }
  &.open {
    display: block;
  }
`;