/**
 *
 * IndexPage
 *
 *
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import SectionHeadline from 'components/elements/SectionHeadline';
import ContentEngine from 'components/content/ContentEngine';
import TeamListItem from 'components/blocks/TeamListItem';

import { graphql } from "gatsby";

const OurStory = ({ data }) => {

  console.log(data);

  const teamList = data.entry.teamList;
  const contentEngine = data.entry.contentEngine;
  
  return (
    <Container>
      <SectionHeadline greenText>
        Leaderboard
      </SectionHeadline>
      <ContentEngine blocks={contentEngine} customBlockStyleClass={'our-story'} />
      
      <img src="/icons/pin-flag.svg" alt="Golf pin icon"/>
      <StyledSubHead>Our Group</StyledSubHead>
      <TeamList>
        {
          teamList.map((item) => (
            <TeamListItem person={item} key={item.fullName}/>
          ))
        }
      </TeamList>
    </Container>
  );
};

OurStory.propTypes = {

};

export default OurStory;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  > img {
    width: 25px;
    height: auto;
    margin: 2em 0;
  }
  section.image-one-up-our-story {
    @media all and (max-width: 700px) {
      img {
        height: 50vh;
        object-fit: cover;
      }
    }
  }
`;

const StyledSubHead = styled.h3`
  color: ${({ theme }) => theme.colors.Primary};
  font-family: 'Averia Serif Libre', serif;
  font-size: 4em;
  margin: 0.5em;
`;

const TeamList = styled.ul`
  max-width: 900px;
  width: 100%;
`;

export const pageQuery = graphql`
  {
    entry: craftLeaderboardLeaderboardEntry {
      ... on Craft_leaderboard_leaderboard_Entry {
        id
        contentEngine {
          ... on Craft_contentEngine_richText_BlockType {
            typeHandle
            id
            body
          }
          ... on Craft_contentEngine_imageOneUp_BlockType {
            typeHandle
            id
            image {
              url
              focalPoint
            }
          }
        }
        teamList {
          ... on Craft_teamList_member_BlockType {
            id
            fullName
            role
            photo {
              url
              focalPoint
            }
            bio
          }
        }
      }
    }
  }
`;
